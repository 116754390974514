import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Shimmer from '../../components/Shimmer';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import ReactPlayer from 'react-player';

import api from '../../services/api';

import logo from '../../assets/images/logo.png';
import base from '../../assets/images/blank-square.png';

export default function Kastel({ id }) {

    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    
    const [loading, setLoading] = useState(true);
    const [loading_page, setLoadingPage] = useState(true);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [logo_hotel, setLogo] = useState('');
    const [courses, setCourses] = useState([]);

    async function loadPage(){
        try{
            const response = await api.post(`/kastel`, { id: 'b919192e-d178-42db-956f-dbfe74d012cc' });
            const data = response.data.data;
            setLogo(data.user.logo);
            setCourses(data.course);
            setLoading(false);
            setLoadingPage(false);
        }catch(err){
            setLoading(false);
            setLoadingPage(false);
        }
    }

    useEffect(() => {
        loadPage();
    }, []);

    return (
        <div className="landing-page-wrapper">
            {loading_page &&
            <div id="loader">
                <div className="loader-content"><div className="sbl-circ-path"></div></div>
            </div>}
            <header className="app-payment bg-light">
                <div className="container p-0">
                    <div className="landing-logo-container">
                        <div className="horizontal-logo">
                            <div className="header-logo d-flex align-items-center justify-content-center gap-5">
                                <img src={logo} className="logo" />
                                {logo_hotel && <img src={logo_hotel} className="logo" />}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="main-content landing-main hotel-title">
                {!loading &&
                <section className="section" data-aos="fade-up">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-7 col-xxl-6 col-md-12 col-lg-8 m-auto">
                                <h4 className="fw-semibold mb-3 text-black">Bem vindo à BRUK.</h4>
                                <p className={`${isMobile ? '' : 'fs-16'} text-justify`}>Somos um conjunto de soluções dentro do segmento fitness. O nosso foco está em atender e prover soluções fitness que sejam não apenas eficientes, mas também acessíveis, especiais e únicas.</p>
                                <p className={`${isMobile ? '' : 'fs-16'} text-justify`}>Com apenas 3 anos no mercado e um crescimento de 400% no ano 2023, a BRUK se destaca através de uma metodologia de treino própria e única, desenvolvida pelo renomado Coach Gus - Eleito em 2018 pela Mens&Health o melhor coach de treinamento do Reino Unido. Hoje temos em nosso time mais de 80 coaches e já impactamos mais de 12.000 vidas através de nossas 10 unidades espalhadas pelo Brasil.</p>
                                <p className={`${isMobile ? '' : 'fs-16'} text-justify`}>A BRUK é a primeira rede do mundo a integrar seus serviços fitness ao segmento hoteleiro, dando ênfase ao turismo do bem-estar, elevando a experiência de hospedagem e garantindo que a rotina de treino dos hospedes seja mantida no mais alto nível.</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-7 col-xxl-6 col-md-12 col-lg-8 m-auto">
                                <div className="embed-container mb-4">
                                    <ReactPlayer
                                        url="https://vimeo.com/982084197"
                                        controls={true}
                                        playsinline={true}
                                        playing={false}
                                        frameBorder="0"
                                        allowFullScreen
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-7 col-xxl-6 col-md-12 col-lg-8 m-auto">
                                <p className={`${isMobile ? 'fs-14' : 'fs-16'} text-black`}>
                                    KASTEL + BRUK - Juntos Transformando sua Experiência de Hospedagem.
                                </p>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-xl-7 col-xxl-6 col-md-12 col-lg-8 m-auto">
                                <div className="row gy-4">
                                    {courses.map((item, index) => (
                                    <div className="col-xl-12" key={item.id_product}>
                                        <div className="card custom-card card-hover card-course">
                                            <Link to={`/kastel-treino/${item.id_product}`}>
                                                <BrowserView>
                                                    <div className="row g-0">
                                                        <div className="col-4 col-md-3">
                                                            <div className="ecommerce-sale-image">
                                                                <img src={base} className="img-fluid rounded-start image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                                                <div className="card-img-overlay d-flex flex-column align-items-start justify-content-end p-3 text-fixed-white">
                                                                    <p className="count-course text-center mb-0">
                                                                        <span className="d-block fs-15 lh-1">
                                                                            <span className="fw-semibold">{item.total}</span> {item.total==1 ? 'aula' : 'aulas'}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-8 col-md-9">
                                                            <div className="card-body p-3 w-100 h-100">
                                                                <div className="w-100 h-100 d-flex flex-column align-items-start justify-content-between">
                                                                    <div>
                                                                        <div className="text-black fs-17 fw-semibold">{item.title}</div>
                                                                        <p className="mb-3">{item.text}</p>
                                                                    </div>
                                                                    <div className="w-100 d-flex align-items-center justify-content-end">
                                                                        <div className="btn btn-primary btn-wave  waves-effect waves-light">Acessar Treino</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </BrowserView>
                                                <MobileView>
                                                    <div className="row g-0">
                                                        <div className="col-4">
                                                            <div className="ecommerce-sale-image">
                                                                <img src={base} className="img-fluid rounded-start image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                                                <div className="card-img-overlay d-flex flex-column align-items-start justify-content-end p-3 text-fixed-white">
                                                                    <p className="count-course text-center mb-0">
                                                                        <span className={`${isMobile ? 'fs-13' : 'fs-15'} d-block lh-1`}>
                                                                            <span className="fw-semibold">{item.total}</span> {item.total==1 ? 'aula' : 'aulas'}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="card-body p-3 w-100 h-100">
                                                                <div className="w-100 h-100 d-flex flex-column align-items-start justify-content-between">
                                                                    <div>
                                                                        <div className="text-black fs-15 fw-semibold">{item.title}</div>
                                                                        <p className="fs-12 mb-1">{item.text_m}</p>
                                                                    </div>
                                                                    <div className="w-100 d-flex align-items-center justify-content-end">
                                                                        <div className="btn btn-sm btn-primary btn-wave waves-effect waves-light">Acessar Treino</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </MobileView>
                                            </Link>
                                        </div>
                                    </div>))}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-7 col-xxl-6 col-md-12 col-lg-8 m-auto">
                                <p className={`${isMobile ? 'fs-14' : 'fs-16'}`}>
                                    <span className="fw-bold mb-3 text-black">BRUK FIT</span><br />
                                    Soluções de Fitness e bem estar.
                                </p>
                                <p className={`${isMobile ? 'fs-14' : 'fs-16'}`}>
                                    Move on,<br />
                                    Be BRUK
                                </p>
                            </div>
                        </div>
                        
                    </div>
                </section>}
            </div>

        </div>
    );
}