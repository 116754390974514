import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop';

import Login from './pages/Login';
import Home from './pages/Home';
import Messages from './pages/Messages';
import Streaming from './pages/Streaming';
import Courses from './pages/Courses';
import Programs from './pages/Programs';
import Program from './pages/Program';
import Calendar from './pages/Calendar';
import News from './pages/News';
import Events from './pages/Events';
import Photos from './pages/Photos';
import Profile from './pages/Profile';
import Privacy from './pages/Privacy';
import Payment from './pages/Payment';
import Hotel from './pages/Hotel';
import Kastel from './pages/Kastel';
import KastelCourse from './pages/KastelCourse';

import Blank from './pages/Blank';

import Page404 from './pages/Page404';

export default function Routing() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" exact element={<Programs />} />
                <Route path="/login" element={<Login />} />
                <Route path="/messages/:id?" element={<Messages />} />
                <Route path="/streaming/:url?" element={<Streaming />} />
                <Route path="/social" element={<Home />} />
                <Route path="/programs/:id?" element={<Programs />} />
                <Route path="/program/:id?" element={<Program />} />
                <Route path="/news/:url?" element={<News />} />
                <Route path="/events/:url?" element={<Events />} />
                <Route path="/photos/:url?" element={<Photos />} />
                <Route path="/profile/:id?" element={<Profile />} />
                <Route path="/courses/:url?" element={<Courses />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/calendar" element={<Calendar />} />

                <Route path="/pagamento/:id?" element={<Payment />} />

                <Route path="/kastel" element={<Kastel />} />
                <Route path="/kastel-treino/:id?" element={<KastelCourse />} />

                <Route path="/hotel" element={<Hotel id="b919192e-d178-42db-956f-dbfe74d012cc" />} />
                
                <Route path="/contract" element={<Blank />} />
                <Route path="/records" element={<Blank />} />
                
                <Route path="/health" element={<Blank />} />
                <Route path="/store" element={<Blank />} />
                <Route path="/notifications" element={<Blank />} />
                <Route path="/settings" element={<Blank />} />
                <Route path="/account" element={<Blank />} />
                {/* <Route path="/gym-class" element={<Blank />} /> */}

                <Route path="*" element={<Page404 />} />
            </Routes>
        </BrowserRouter>
    );
}